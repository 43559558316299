import { createRouter, createWebHashHistory } from 'vue-router'
// import Login from "@/views/Login";
const routes = [
  //默认
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Home.vue'),
  },
    //管理员
  // {
  //   path: '/admin',
  //   name: 'adminLayout',
  //   component: AdminLayout,
  //   // redirect: "/home",
  //   children:[
  //     {
  //       path: '/user',
  //       name: 'user',
  //       component:  () => import('@/views/admin/User.vue')
  //     }
  //
  //   ]
  // },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/Test.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
